export type ImageUriType =
  | "small"
  | "normal"
  | "large"
  | "png"
  | "art_crop"
  | "border_crop";

export type ImageUris = Record<ImageUriType, string>;

export interface MtgCard extends Record<string, any> {
  name: string;
  image_uris?: ImageUris;
  card_faces?: [MtgCard, MtgCard];
  mana_cost: string;
  released_at: string;
  type_line?: string;
  produced_mana?: string[];
  keywords?: string[];
}

export class CardService {
  static SHOW_TYPE: ImageUriType = "art_crop";
  static GAME_TYPE: ImageUriType = "border_crop";

  static getSorted(cardName: string, cards: MtgCard[] = []): MtgCard[] {
    const exact = cards.filter(
      ({ name }) => name.toUpperCase() === cardName.toUpperCase(),
    );
    const start = cards.filter(({ name }) =>
      name.toUpperCase().startsWith(cardName.toUpperCase()),
    );
    return exact.length ? exact : start.length ? start : cards;
  }

  static getImageUrl(
    card: MtgCard,
    type: ImageUriType = CardService.GAME_TYPE,
  ) {
    const images = card.card_faces?.[0]?.image_uris || card.image_uris;
    const original = images[type] || "";
    const art = original
      .replace(/\?\d+$/, "")
      .replace("https://cards.scryfall.io/", "");

    return `/api/art/${encodeURIComponent(art)}`;
  }

  static async getCard(cardName: string, set: string): Promise<MtgCard> {
    try {
      const nameAndSet = `${cardName}&set=${set}`;
      const cardUrl = `/api/card/${encodeURIComponent(nameAndSet)}`;
      const response = await fetch(cardUrl);

      return (await response.json()) as MtgCard;
    } catch (error) {
      console.warn(error.message || error);

      const cardUrl = `/api/card/${encodeURIComponent(cardName)}`;
      const response = await fetch(cardUrl);

      return (await response.json()) as MtgCard;
    }
  }
}
